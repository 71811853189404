<template>
	<!-- wrap -->
	<div id="wrap" class="loginWrap v2">
		<!-- titArea -->
		<div class="titArea">
			<div class="inner">
				<h2>비밀번호 찾기</h2>
				<a class="btnBack" @click="login()">이전 페이지</a>
			</div>
		</div>
		<!--// titArea -->
		<!-- container -->
		<div id="container">
			<!-- contents -->
			<div class="contents">
				<!-- contCenter -->
				<div class="contCenter" v-if="!find">
					<!-- inputList -->
					<div class="inputList">
						<dl>
							<dt>아이디</dt>
							<dd>
								<input
									type="text"
									title="아이디 입력"
									placeholder="아이디(이메일주소)를 입력해주세요."
									required="required"
									v-model="loginId"
									maxlength="50"
									@keyup.enter="findBtn ? onFindUser() : null"
									ref="loginId"
								/>
							</dd>
						</dl>
						<dl>
							<dt>닉네임</dt>
							<dd>
								<input
									type="text"
									title="닉네임 입력"
									placeholder="닉네임을 입력해주세요."
									required="required"
									v-model="nickname"
									maxlength="20"
									@keyup.enter="findBtn ? onFindUser() : null"
								/>
							</dd>
						</dl>
					</div>
					<!--// inputList -->
					<!-- btnArea -->
					<div class="btnArea">
						<button type="button" class="btn" @click="onFindUser()" :disabled="!findBtn">비밀번호 재설정</button>
					</div>
					<!--// btnArea -->
				</div>
				<!--// contCenter -->
				<div class="contCenter" v-if="find">
					<!-- inputList -->
					<div class="inputList">
						<dl>
							<dt>새로운 비밀번호 <em>*</em></dt>
							<dd>
								<input
									type="password"
									title="새로운 비밀번호 입력"
									placeholder="새로운 비밀번호(영문,숫자,특수문자포함 8~20자)"
									required="required"
									v-model="password"
									maxlength="20"
									@keyup.enter="findBtn2 ? onPasswordChange() : null"
									ref="password"
								/>
							</dd>
						</dl>
						<dl>
							<dt>새로운 비밀번호 확인 <em>*</em></dt>
							<dd>
								<input
									type="password"
									title="새로운 비밀번호 재입력"
									placeholder="새로운 비밀번호를 한번 더 입력해주세요."
									required="required"
									v-model="password2"
									maxlength="20"
									@keyup.enter="findBtn2 ? onPasswordChange() : null"
								/>
							</dd>
						</dl>
					</div>
					<!--// inputList -->
					<!-- btnArea -->
					<div class="btnArea">
						<button type="button" class="btn" @click="onPasswordChange()" :disabled="!findBtn2">확인</button>
					</div>
					<!--// btnArea -->
				</div>
				<!--// contCenter -->
			</div>
			<!--// contents -->
		</div>
		<!--// container -->
	</div>
	<!--// wrap -->
</template>
<script>
import users from '@/api/user';
import { showSpinner, hideSpinner } from '@/utils/cmm';
export default {
	data() {
		return {
			loginId: '',
			nickname: '',
			id: '',
			find: false,
			password: null,
			password2: null,
		};
	},
	mounted() {
		this.$refs.loginId.focus();
	},
	computed: {
		findBtn: {
			get() {
				return this.loginId.length > 0 && this.nickname.length;
			},
			set() {},
		},
		findBtn2: {
			get() {
				return this.password && this.password2;
			},
			set() {},
		},
	},
	methods: {
		login() {
			this.$router.push({ name: 'login' });
		},
		async onFindUser() {
			try {
				let res = await users.find2(this.loginId, this.nickname);
				this.id = res.id;
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			}
			this.find = true;
			this.$nextTick(() => this.$refs.password.focus());
		},
		async onPasswordChange() {
			showSpinner();
			try {
				await users.updatePassword(this.id, this.password, this.password2);
			} catch (ex) {
				return await this.$alert(`${ex.message}`);
			} finally {
				hideSpinner();
			}
			await this.$alert(`비밀번호가 재설정되었습니다.`);
			this.$router.push({ name: 'login' });
		},
	},
};
</script>
